.pag {
    background-color: rgb(134, 134, 228);
    border-radius: 10px;
    color: white;


}

.nextAndPrev {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;

}

.nextAndPrevBtn {
    padding: 5px 20px;
    background-color: blue;
    color: white;
    border: 2px solid black;

}

.nextAndPrevBtn:disabled {
    opacity: 0.4;
}

.next {
    border-radius: 0px 10px 10px 0px;
}

.prev {
    border-radius: 10px 0px 0px 10px;
}

.retestt {
    position: relative;
    top: 50;
    left: 50;
    /* transform: translate(-50%, -50%); */
}