@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Cairo';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Styling the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* Width of the scrollbar */
}

/* Styling the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #517d8a;
  /* Color of the scrollbar thumb */
  border-radius: 3px;
  /* Round corners */
}

/* Styling the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background: none;
  /* or background: transparent; */
}

/* Styling the scrollbar track in Firefox */
.scrollbar {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  /* or auto to hide on non-scrollable content */
}

/* Styling the scrollbar thumb in Firefox */
.scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

a {
  color: #517d8a;
}

Link {
  background-color: #517d8a;
}

.bgteal {
  background-color: #517d8a;
  transition: all 0.2s;
}

.bgteal:hover {
  background-color: rgba(53, 163, 163, 0.949);
}

/* #c7b09b */
/* #517d8a */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox and Microsoft Edge */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}