#linkOfSettings:hover {
  text-decoration: underline;
}

.text-red {
  color: red;
}

#profileImg {
  border-radius: 50%;
  /* width: 170px;
    height: 150px; */
}

.titleOfSkills,
.titleOfServices {
  margin: 20px;
  color: teal;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.titleOfServices {
  color: teal;
}

.profileHeader {
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
}


.profileComplete {
  width: 19rem;
}

.progressItems {
  display: grid;
  grid-template-columns: auto auto;
}

.progressIcon svg {
  display: inline;
}


@media screen and (max-width: 600px) {
  .profileHeader {
    flex-direction: column;
  }

  .profileComplete{
    margin-top: 1rem;
    width: 16rem;
  }
  
}


.skillContainer {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skills {
  background-color: teal;
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: calc(90% / 6);
  box-shadow:
    6px 9px 6px 0px #80808082,
    6px 9px 6px 0px #fef3f382;
  transition: all 0.5s;
  overflow: auto;
}

@media (max-width: 900px) {
  .skills {
    margin: 1rem 0px;
    width: calc(90% / 3);
  }
}

@media (max-width: 500px) {
  .skills {
    margin: 1rem 0px;
    width: calc(90% / 2);
  }
}

.about {
  border: 5px double black;
  background-color: #eee;
  color: black;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
}

.services {
  border: 2px solid black;
  margin: 10px;
  padding: 10px;
  width: 45%;
  background-color: #eeeeee;
  box-shadow:
    6px 9px 6px 0px #80808082,
    6px 9px 6px 0px #fef3f382;
  overflow: auto;
}

/* .cover {
  width: 80%;
  margin: 10px auto;
  height: 100px;
  border: 2px dotted rgb(0, 0, 0);

} */

.coverImg {
  width: 100%;
  height: 100%;
}

.serviceContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.hrInService {
  width: 80%;
  margin: 10px auto;
}

.buttonInService {
  padding: 10px;
  background-color: teal;
  color: white;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px;
  border: 2px solid black;
}

.nextAndPrev {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  color: rgb(158, 158, 158);
}

@media (max-width: 600px) {
  .nextAndPrev {
    width: 100%;
  }
}

.nextAndPrevBtn {
  padding: 5px 10px;
  background-color: blue;
  /* color: white; */
  border: 2px solid black;
  border-radius: 10px;
}

.nextAndPrevBtn:disabled {
  opacity: 0.4;
}

.startAndEnd {
  padding: 10px 15px;
  background-color: rgba(5, 152, 152, 0.875);
}

.asas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.asas .servCont {
  width: calc(90% / 3);
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.EditPenSpan {
  position: absolute;
  top: -13px;
  right: -13px;
  background-color: teal;
  opacity: 0.3;
  padding: 15px;
  border-radius: 30%;
  cursor: pointer;
  transition: 0.3s all;
}

.EditPenSpan:hover {
  opacity: 1;
}

@media (max-width: 800px) {
  .asas .servCont {
    width: calc(90% / 2);
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .asas .servCont {
    width: 90%;
    margin-bottom: 10px;
  }
}

/* ==================== */
#editText {
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  background-color: greenyellow;
  margin: 10px;
  text-align: center;
}

.skilll {
  transition: 0.3s all;
}

.skilll:hover {
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: 0px 2px 4px 0px gray;
}

@media (max-width: 650px) {
  #profileImg {
    width: 200px;
    height: 115px;
  }
}

.quote {
  width: fit-content;
  text-align: center;
  border-radius: 5px;
}

.dgrid {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "prof prof prof prof prof prof prof prof prof prof prof prof prof prof prof"
    "pinfo pinfo pinfo pinfo pinfo pinfo pinfo pskills pskills pskills pskills pskills pskills pskills pskills";
  gap: 1rem;
}

@media (max-width: 768px) {
  .dgrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "prof prof prof prof prof prof prof "
      "pinfo pinfo pinfo pinfo pinfo pinfo pinfo " "pskills pskills pskills pskills pskills pskills pskills";
    gap: 1rem;
  }
}

.prof {
  grid-area: prof;
}

.pinfo {
  grid-area: pinfo;
}

.pskills {
  grid-area: pskills;
}

.fontdecorationProfile {
  font-size: 20px;
  font-weight: bold;
}

.linkEditProfile:hover {
  color: #517d8a;
}

.texttColor {
  color: #517d8a;
}

.message-container {
  /* display: block; */
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  /* width: 310%; */
  text-align: center;
  margin: 1rem;
}

.message-container-two {
  background-color: rgba(0, 128, 128, 0.378);
  opacity: 0.5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    display: block;
    opacity: 1;
  }
}

.message-container p {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.message-container a {
  color: teal;
  text-decoration: none;
  font-weight: bold;
}

.message-container a:hover {
  text-decoration: underline;
}

.file-input-label {
  display: inline-block;
  background-color: #0077cc;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.file-input-label:hover {
  background-color: #0055aa;
}

.file-input {
  display: none;
  /* Hide the actual input */
}

/* Style the label to make it look like a button */
