.modal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width:400px) {
    .modal-container {
        top: 50%;
    }
}

.titleOfNotf {
    color: darkblue;
    font-weight: bold;
}

.timeOfNotf {
    color: crimson;
    font-weight: bold;
}

.modal-containerr {
    position: fixed;
    left: 35%;
    top: 70%;
    transform: translate(-30%, -50%);
}

@media (min-width:1023px) {
    .modal-containerr {
        position: fixed;
        left: 70%;
        top: 70%;
        transform: translate(-60%, -30%);
    }

}

/* .sideBar {
    background-image: linear-gradient(to top, #517d8a, #1b232c);
} */

.modal-container-two {
    position: fixed;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    /* z-index: -1000; */
    /* background: rgba(0, 128, 128, 0.516); */

}

/* c7b09b */
/* .modal-container {
    width: 80%;
    left: 35%;
    top: 90%;
    transform: translate(-30%, -50%);
    height: min-content;
}

@media (min-width:1000px) {
    .modal-container {
        width: 80%;
        left: 60%;
        top: 80%;
        transform: translate(-50%, -50%);
        height: min-content;
    }
}

.ppoasd {
    max-width: 300px;
    max-height: 300px;
} */
.showMore {
    position: relative;
}

.showMore:hover .animation {
    animation-play-state: paused !important;
}

.animation {
    position: relative;
    animation-name: upAndDown;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.animation:nth-child(2) {
    animation-delay: 0.25s;
}

.animation:nth-child(3) {
    animation-delay: 0.5s;
}

.animation:nth-child(4) {
    animation-delay: 0.75s;
}


@keyframes upAndDown {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}