.contract {
    width: 100%;
    margin: auto;
    background-color: white;
    color: black;
    padding: 20px;
    position: relative;
    font-family: Arial, sans-serif;
}

.title {
    text-align: center;
}

.pTitle {
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding: 20px;
}

.pTitle p {
    margin: 0px 10px;
}

.details {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.detailsOfUsers {
    background-color: #eee;
    border: 3px solid black;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    margin: 20px auto;

}

.pp {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.segtTaakod {
    background-color: #eee;
    border: 3px solid black;
    padding: 10px;
    width: 100%;
    margin: 20px auto;
    border-radius: 10px;
    text-align: center;
}

.buttonsContainer {
    position: relative;
    bottom: 20px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    background-color: white;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    padding: 20px;
}

.buttons button {
    padding: 10px 20px;
    background-color: rgb(111, 174, 238);
    border: 1px solid black;
    border-radius: 2px;
    color: white;
    justify-content: center;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 768px) {
    .pTitle {
        flex-direction: column;
        align-items: center;
        font-size: 24px;
    }

    .details {
        align-items: center;
    }

    .detailsOfUsers {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    .pTitle {
        font-size: 20px;
    }

    .pp {
        font-size: 18px;
    }

    .segtTaakod {
        font-size: 16px;
    }

    .buttons {
        flex-direction: column;
    }

    .buttons button {
        width: 100%;
    }
}

@media print {

    .buttonsContainer {
        display: none;
    }
}

.contractContainer {
    border: 3px double gray;
    padding: 1rem;
    margin: 1rem auto;
    width: 80%;
    position: relative;
}

.contractContainer::after {
    position: absolute;
    content: "";
    background-image: url("../../images/logo/logo\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    opacity: 0.3;
    transform: translate(-50%, -50%);

}

.dataDetails {
    background-color: #f1fbfc;
    padding: 1rem 0;
}

.dataTitle {
    box-shadow: 0px 2px 0px 0px #cfcfcf, 0px 4px 0px 0px gray;
    width: fit-content;
    width: -moz-fit-content;
}