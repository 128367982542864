.complainDetailsContainer {
    border: 2px solid gray;
    border-radius: 1rem;
    width: 85%;
    margin: 2rem auto;
    padding: 1rem;
}

.line {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid gray;
    padding: 1rem 0;
}

.line:last-child {
    border-bottom: 0;
}

.titleOfComplaint {
    width: 30%;
}

.line .detailsOfComplaint {
    width: 60%;
}