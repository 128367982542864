.buttonInBC {
    width: calc(90% / 4);
    transition: 0.5s all;
    text-align: center;
}

@media (max-width:850px) {
    .buttonInBC {
        width: calc(90% / 2);
        margin-bottom: 10px;

    }
}

#select {
    width: 100%;
    padding: 10px;
    border: 2px solid black;
}



.tableSelectJumpers td:last-child {
    border: 0px solid gray;
}

.imgInJS {
    border-radius: 50%;
}

.trInJS {
    background-color: #eee;
    text-align: center;
}

.serviceImages {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr auto;
    grid-template-areas: ". cover cover cover cover cover cover cover cover cover cover ."
        ". imgs imgs imgs imgs imgs imgs imgs imgs imgs imgs .";
    gap: 1rem;
}

.serviceImages .desc {
    grid-area: desc;
    overflow: auto;
}

.serviceImages .cover {
    grid-area: cover;

}

.serviceImages .cover img {
    width: 100%;
}

.serviceImages .images {
    grid-area: imgs;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.serviceImages .images div {
    height: fit-content;
}

.serviceImages .images img {
    width: 100%;
}

@media (max-width:850px) {
    .serviceImages .images {
        grid-area: imgs;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
    }

    .serviceImages .images div {
        width: calc(95% /2);
    }

    .serviceImages {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto 1fr;
        grid-template-areas: ". cover cover cover cover cover cover cover cover cover cover ."
            ". imgs imgs imgs imgs imgs imgs imgs imgs imgs imgs .";
        gap: 1rem;
    }

}

@media (max-width:450px) {
    .serviceImages .images {
        grid-area: imgs;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
    }

    .serviceImages .images div {
        width: 100%;
    }

    .serviceImages {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto 1fr;
        grid-template-areas: ". cover cover cover cover cover cover cover cover cover cover ."
            ". imgs imgs imgs imgs imgs imgs imgs imgs imgs imgs .";
        gap: 1rem;
    }

}

.inputDesign {
    border: 2px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}


.inputDesignSelected {
    border-color: blue;
    background-color: #e6f7ff;
    color: #000;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column-reverse; */
    /* flex-wrap: wrap; */
}

.buttonsContainer div {
    margin: 0.5rem;
    /* width: 100%; */
}

.buttonsContainer .rounded {
    width: 100%;
}


@media (max-width:800px) {
    .buttonsContainer {
        flex-wrap: wrap;
    }

    .buttonsContainer div {
        width: 40%;
    }
}

.imgInJP {
    width: 150px;
    height: 200px;
}

.ajjs {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    text-align: center;
}

@media (max-width:350px) {
    .ajjs {
        padding: 0;
    }
}

.trans {
    transition: all 1s;
}

.privacyModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 40%;
    z-index: 1000;
    width: 100%;
    height: 100%;
}

@media (max-width: 1025px) {
    .privacyModalContainer {
        top: 50%;
        right: 10%;
    }
}