.review-content {
    display: flex;
    gap: 0.8rem;
}



.starBtn {
    color: orange;
    cursor: pointer;
}

.stars {
    display: flex;
}

.AiFill {
    color: orange;
}

.AiFill:hover {
    color: orange;
}