.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1.5em;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

#logo {
  width: 30px;
  height: 30px;
}

#logoAndText {
  display: flex;
  justify-content: center;
  align-items: center;
}

#textinSignIn {
  font-size: 25px;
  font-weight: bold;
}

#err {
  color: red;
}

#smallErr {
  color: red;
}

#phoneIcon {
  font-size: 25px;
}

.reSendOTP .sa {
  color: rgb(71, 71, 248);

}

.counter {
  color: rgb(71, 71, 248);
  ;
}

#anthorIcon {
  font-size: 20px;
}

.inputPhone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-color: transparent;
}

.spanPhone {
  display: flex;
  align-items: center;
  border: 1px solid black;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-color: transparent;
}

#submitBtn {
  background-color: teal;
}

#submitBtn:hover {
  background-color: rgba(0, 155, 155, 0.826);

}

.containerOfPP {
  padding: 3rem 6rem;
  color: gray;
  direction: rtl;
}

.f {
  font-size: 30px;

}

.ff {
  margin-top: 2rem;
}

.fc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.linkCC {
  color: rgba(0, 161, 161, 0.89);
}

.radioDesign {
  border: 2px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
}

.radioDesign:hover {
  background-color: #f0f0f0;
}

.radioSelected {
  border-color: blue;
  background-color: #e6f7ff;
  color: #000;
}